import DatoModel from './dato-model.js'
import { verseFragmentBody } from './dato-verse-model.js'

const fields = [
  {
    id: 'body',
    constraints: { presence: true, maxConsecutiveBlankLines: 1, length: { minimum: 50 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'prayer',
    constraints: { presence: true, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'reflection',
    constraints: { presence: true, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'title',
    constraints: { presence: true, length: { minimum: 3 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'verseReflection',
    constraints: { presence: true, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  { 
    id: 'annotations',
    json: true
  },
  { id: 'needsProofreading' },
  { id: 'reflectionShortId' },
  {
    id: 'shortId',
    constraints: { presence: true },
  },
  { id: 'source' },
  { 
    id: 'sourceData', 
    json: true 
  },
  { id: 'specialDay' },
  { id: 'substantialEdits' },
  { id: 'verseReflectionShortId' },
  { 
    id: 'bodyEs',
    constraintsEs: { presence: true, maxConsecutiveBlankLines: 1, length: { minimum: 50 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  { 
    id: 'prayerEs',
    constraintsEs: { presence: true, dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  { 
    id: 'reflectionEs',
    constraintsEs: { presence: true, dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  { id: 'sourceEs' },
  { 
    id: 'titleEs' ,
    constraintsEs: { presence: true, length: { minimum: 3 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  { 
    id: 'verseReflectionEs',
    constraintsEs: { presence: true, dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  { id: 'needsProofreadingEs' }
]

const links = [
  {
    id: 'audio'
  },
  {
    id: 'author',
    constraints: { presence: true }
  },
  { id: 'linkedVerses' },
  {
    id: 'translatorEs',
    constraintsEs: { presence: true }
  },
  {
    id: 'verse',
    constraints: { presence: true }
  }
]

const fragment = `fragment __NAME__ on DevotionRecord {
  annotations
  body
  bodyEs
  id
  needsProofreading
  needsProofreadingEs
  prayer
  prayerEs
  reflection
  reflectionEs
  shortId
  source
  sourceEs
  sourceData
  specialDay
  substantialEdits
  title
  titleEs
  translatorEs {
    name
  }
  status: _status
  verseReflection
  verseReflectionEs
  audio {
    id
    filename
    muxId
    muxPlaybackId
    needsProofreading
  }
  author {
    name
  }
  linkedVerses {
    ${verseFragmentBody}
  }
  verse {
    ${verseFragmentBody}
  }
}`

const Devotion = new DatoModel('devotion', fields, links, fragment)
Object.freeze(Devotion)

export default Devotion
