import DatoModel from './dato-model.js'
import { verseFragmentBody } from './dato-verse-model.js'

const fields = [
  {
    id: 'intro',
    constraints: { maxConsecutiveBlankLines: 1, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'title',
    constraints: { presence: true, length: { minimum: 5 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'verses',
    constraints: { presence: true },
    userEditable: true
  },
  {
    id: 'shortId',
    constraints: { presence: true },
  },
  { 
    id: 'annotations',
    json: true
  },
  { id: 'source' },
  { 
    id: 'sourceData', 
    json: true 
  },
  { id: 'needsProofreading' },
]

const links = [
  { 
    id: 'author',
    constraints: { presence: true }
  },
  { id: 'linkedVerses' }
]

const fragment = `fragment __NAME__ on ListRecord {
  annotations
  id
  intro
  needsProofreading
  shortId
  source
  sourceData
  title
  verses
  author {
    name
  }
  linkedVerses {
    ${verseFragmentBody}
  }
}`

const List = new DatoModel('list', fields, links, fragment)
Object.freeze(List)

export default List
