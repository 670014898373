import _ from 'lodash'
import BibleMeta from './bible-meta.js'

const bibleMeta = new BibleMeta()

const en = Object.freeze({
  id: 'en',
  title: 'English',
  getLocalized(obj, keyPath) {
    return _.get(obj, keyPath)
  },
  getLocalizedVerseContent(verse) {
    return _.get(verse, 'contentWeb')
  },
  getLocalizedVerseReference(verse) {
    return _.get(verse, 'reference')
  },
  getLocalizedKey(key) {
    return key
  }
})

const es = Object.freeze({
  id: 'es',
  title: 'Spanish',
  getLocalized(obj, keyPath) {
    if (_.isEmpty(keyPath)) {
      return null
    }

    const components = keyPath.split('.')
    if (components.length > 0) {
      components[0] = `${components[0]}Es`
    } 
    return _.get(obj, components.join('.'))
  },
  getLocalizedVerseContent(verse) {
    return _.get(verse, 'contentRva')
  },
  getLocalizedVerseReference(verse) {
    const reference =  _.get(verse, 'reference')
    try {
      const localizedReferences = bibleMeta.localizeReference(reference)
      return _.get(localizedReferences, 'es', reference)
    } catch (error) {
      console.error('Could not get verse reference', error)
      return reference
    }
  },
  getLocalizedKey(key) {
    return `${key}Es`
  }
})

const Language = Object.freeze({
  en: en,
  es: es,
  default: en,
  all: [en, es],
  fromId: (id) => {
    if (id === en.id) {
      return en
    } else if (id === es.id) {
      return es
    } else {
      return null
    }
  }
})

export default Language
