import React from 'react'
import { Badge, Col, Container, Row } from 'react-bootstrap'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import useFirebaseAuth from '../hooks/use-firebase-auth'
import { PRESENT_ERROR, SET_PAGE_TITLE } from '../lib/actions'
import Permissions from '../lib/permissions'
import ApiClient from '../services/api-client'
import { auth } from '../services/firebase-services'
import BaseContext, { defaultContext } from './base-context'
import SignInView from './sign-in-view'

const helmetContext = {}

function BasePage({ children, hideBreadcrumbs = false, parent, title: initialTitle }) {
  // Expects title/url objects, with last one being 
  const [user] = useFirebaseAuth()
  const [context, setContext] = React.useState(defaultContext)
  const [title, setTitle] = React.useState(initialTitle)
  const onSignOutClick = async (e) => {
    e.preventDefault()
    await auth.signOut()
  }

  React.useEffect(() => {
    if (user) {
      const getToken = async () => {
        const token = await user.getIdTokenResult()
        const newContext = { ...defaultContext }
        newContext.apiClient = new ApiClient(user, token.claims)
        newContext.permissions = new Permissions(user, token.claims)
        newContext.user = user
        setContext(newContext)
      }
      getToken()
    } else {
      setContext(defaultContext)
    }
  }, [user])

  React.useEffect(() => {
    defaultContext.register((payload) => {
      switch (payload.type) {
        case SET_PAGE_TITLE:
          setTitle(payload.title)
          break
        case PRESENT_ERROR:
          const error = payload.error
          alert((error && error.message) || 'Unknown error')
          break
        default:
          break
      }
    })
  }, [])

  // Flexbox automatically sizes columns around center column
  return (
    <BaseContext.Provider value={context}>
      <HelmetProvider context={helmetContext}>
        <Helmet title={title}>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossOrigin="anonymous" />
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css" />
        </Helmet>
        <Container fluid className="mt-3 mb-5">
          <Row>
            <Col>{context.sandbox && <Badge bg="warning">Sandbox</Badge>}</Col>
            <Col md="6" className="text-center">
              <Container>
                <Row>
                  <Col>
                    {hideBreadcrumbs && <small>&nbsp;</small>}
                    {!hideBreadcrumbs && <small>
                      <a href="/" className="text-decoration-none">Joyverse CMS</a>
                      {parent && " › "}
                      {parent && <a href={parent.url} className="text-decoration-none">{parent.title}</a>}
                    </small>}
                    <h2>{title}</h2>

                    {!context.user &&
                      <Container>
                        <Row>
                          <Col></Col>
                          <Col lg="10" xl="8"><SignInView /></Col>
                          <Col></Col>
                        </Row>
                      </Container>}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col>
              {context.user && <small className="float-end text-muted">{context.user.email} · <a href="#" onClick={onSignOutClick} className="text-decoration-none">Sign out</a></small>}
            </Col>
          </Row>
          <Row>
            <Col className="mt-4">
              {context.user && children}
            </Col>
          </Row>
        </Container>
      </HelmetProvider>
    </BaseContext.Provider>
  )
}

export default BasePage
