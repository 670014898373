import DatoModel from './dato-model.js'

const fields = [
  { id: 'date' },
  { id: 'shortId' },
]

const links = [
  { id: 'devotion' },
  { id: 'image' },
  { id: 'imageOptions' },
]

const DailyBibleModel = new DatoModel('daily_bible', fields, links)
Object.freeze(DailyBibleModel)

export default DailyBibleModel
