const invariant = require('invariant')

// Adopted from https://github.com/facebook/flux/blob/master/src/Dispatcher.js, removing support for WaitFor.
class Dispatcher {
  constructor() {
    this._callbacks = {}
    this._isDispatching = false
    this._lastID = 1
  }

    /**
   * Is this Dispatcher currently dispatching.
   */
  get isDispatching() {
    return this._isDispatching
  }

  /**
   * Dispatches a payload to all registered callbacks.
   */
  dispatch(payload) {
    invariant(
      !this._isDispatching,
      'Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch.'
    )
    this._isDispatching = true
    try {
      for (var id in this._callbacks) {
        this._callbacks[id](payload)
      }
    } finally {
      this._isDispatching = false
    }
  }

  register(callback) {
    var id = 'ID_' + this._lastID++
    this._callbacks[id] = callback
    return id
  }

  /**
   * Removes a callback based on its token.
   */
  unregister(id) {
    invariant(
      this._callbacks[id],
      'Dispatcher.unregister(...): `%s` does not map to a registered callback.',
      id
    )
    delete this._callbacks[id]
  }
}

export default Dispatcher
