import validate from 'validate.js'

import BibleMeta from './bible-meta.js'
import PunctuationChecker from './punctuation-checker.js'

const meta = new BibleMeta()
const punctuationChecker =  new PunctuationChecker()

// Custom validators for validate.js

// Takes option as a number as an option, example: { maxConsecutiveBlankLines: 2 }
function maxConsecutiveBlankLines(value, options, key, attributes) {
  if (!value) {
    return null
  }
  const maxLines = Number(options)
  const lines = value.split('\n')
  let blankLines = 0
  for (const line of lines) {
    if (line.match(/\S/)) {
      blankLines = 0
    } else {
      blankLines += 1
      if (blankLines > maxLines) {
        return `has consecutive empty lines that exceed ${maxLines} lines`
      }
    }
  }
  return null
}

function dashes(value, options, key, attributes) {
  if (value && punctuationChecker.hasInvalidDashes(value)) {
    return `has invalid dashes`
  }
  return null
}

function ellipses(value, options, key, attributes) {
  if (value && punctuationChecker.hasInvalidEllipses(value)) {
    return `has invalid ellipses`
  }
  return null
}

function smartQuotes(value, options, key, attributes) {
  if (value && punctuationChecker.hasInvalidQuotationMarks(value)) {
    return `has straight quotation marks`
  }
  return null
}

function verse(value, options, key, attributes) {
  // Already matches validate style messages "is…"
  const error = meta.validateReference(value)
  if (error) {
    return error
  } else {
    return null
  }
}

validate.validators.dashes = dashes
validate.validators.ellipses = ellipses
validate.validators.maxConsecutiveBlankLines = maxConsecutiveBlankLines
validate.validators.smartQuotes = smartQuotes
validate.validators.verse = verse

export default validate
