class PunctuationChecker {
  hasInvalidQuotationMarks(text) {
    return text.match(/["|'|`]/) != null
  }

  hasInvalidEllipses(text) {
    // Look for ellipses that have a space before, 
    return text.match(/\s…|…[^. ”]|\.\.\./) != null
  }

  hasInvalidDashes(text) {
    // Look for hyphens with spaces before (and a non-digit after) | or after | multiple hyphens | any en dash |
    // em dashes with a non-space (which includes another em dash) before | or after | em dashes with 2 or more spaces before | or after
    return text.match(/(\s-\D|-\s|-{2,}|–|\S—|—\S|\s{2,}—|—\s{2,})/) != null
  }
}

export default PunctuationChecker