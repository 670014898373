import AudioModel from '../../common/dato-audio-model.js'
import DailyBibleModel from '../../common/dato-daily-bible-model'
import DevotionModel from '../../common/dato-devotion-model'
import ImageModel from '../../common/dato-image-model'
import ListModel from '../../common/dato-list-model'
import TopicModel from '../../common/dato-topic-model'
import VerseModel from '../../common/dato-verse-model'

class Permissions {
  constructor(user, claims) {
    this._searchDevotions = false
    if (!user || !claims) {
      this._hasDatoAccess = false
    } else {
      this._hasDatoAccess = (claims && claims.dato) ? true : false
      switch (user.email) {
        case 'daniel@joyverse.co':
          this.isAdmin = true
          this._editable = new Set([AudioModel, DailyBibleModel, DevotionModel, ImageModel, ListModel, TopicModel, VerseModel])
          this._import = true
          this._proofreadable = new Set([AudioModel, DevotionModel, ListModel, TopicModel, VerseModel])
          this._viewable = new Set([DailyBibleModel, DevotionModel, ImageModel, ListModel, TopicModel, VerseModel])
          break
        case 'ami@amihendrickson.com':
          this._editable = new Set([AudioModel, DailyBibleModel, DevotionModel, ListModel, TopicModel, VerseModel])
          this._import = true
          this._proofreadable = new Set([AudioModel, DevotionModel, ListModel, TopicModel, VerseModel])
          this._viewable = new Set([DailyBibleModel, DevotionModel, ListModel, TopicModel, VerseModel])
          break
        case 'kenyoophd@gmail.com':
          this._editable = new Set([VerseModel])
          this._import = true
          this._viewable = new Set([DailyBibleModel, DevotionModel, ListModel, TopicModel, VerseModel])
          break
        default:
          break
      }
    }
  }

  canCreate(model) {
    return this._hasDatoAccess && this._import
  }

  canEdit(model) {
    if (this._hasDatoAccess) {
      return this._editable && this._editable.has(model)
    } else {
      return false
    }
  }

  canProofread(model) {
    if (this._hasDatoAccess) {
      return this._proofreadable && this._proofreadable.has(model)
    } else {
      return false
    }
  }

  canView(model) {
    if (this._hasDatoAccess) {
      return this._viewable && this._viewable.has(model)
    } else {
      return false
    }
  }
}

export default Permissions
