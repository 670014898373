import React from 'react'

import { auth } from '../services/firebase-services'

const useFirebaseAuth = () => {
  const [loading, setLoading] = React.useState(!auth.initialized)
  const [user, setUser] = React.useState(auth.currentUser)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoading(false)
    }, (error) => {
      setError(error)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])
  return [user, loading, error]
}

export default useFirebaseAuth
