import Debug from 'debug'

const debug = Debug('joycms:bible')

class VerseValidationError extends Error {
  constructor(reference, validationErrors) {
    super(`Invalid verse reference ${reference}: ${validationErrors.join(', ')}`)
    this._reference = reference
    this._validationErrors = validationErrors
    Error.captureStackTrace(this, VerseValidationError)
  }

  get reference() { return this._reference }

  get validationErrors() { return this._validationErrors }
}

class BibleMeta {
  constructor() {
    const books = BibleBooksData.split('\n').map(line => {
      const columns = line.split('\t')
      const id = parseInt(columns[1])
      // Key, value
      return {
        key: columns[0],
        id: id,
        chapterVerseCounts: columns[2].split(' ').map(c => parseInt(c))
      }
    })
    this._books = books.reduce((map, book) => map.set(book.key, book), new Map())
    this._localizedNames = BibleBookLocalizations
  }

  allBooks() {
    return Array.from(this._books.keys()).sort()
  }

  allChapters(book) {
    const bookMeta = this._books.get(book)
    if (!bookMeta) {
      console.error('Invalid book', book)
    }
    // 1 based
    const chapterCount = bookMeta.chapterVerseCounts.length
    return Array.from(new Array(chapterCount), (x, i) => i + 1)
  }

  allVerses(book, chapter) {
    const bookMeta = this._books.get(book)
    // 1 based
    const verseCount = bookMeta.chapterVerseCounts[chapter - 1]
    return Array.from(new Array(verseCount), (x, i) => i + 1)
  }

  // Assumes reference is valid, non-null
  autocorrectReference(reference) {
    if (!reference) {
      return reference
    }

    let correctedReference = reference
      .replace(/\s{2,}/g, ' ') // Make all contiguous spaces 1
      .replace(/[–—]/g, '-') // Replace en and em dashes with regular dashes
      .replace(/:\s+/, ':') // Remove spaces after colon. Word seems to be auto-correcting with space 
      .replace(/\s+-\s*|-\s+/, '-') // Remove spaces around dash.
      .replace(/\bPsalms\b/g, 'Psalm')
      .split(';').map(ref => {
        // Standardize spacing of , and ; to just 1 postfix
        return ref.split(',').map(subRef => subRef.trim())
          .join(', ')
      }).join('; ')

    if (reference != correctedReference) {
      debug('Corrected %s -> %s', reference, correctedReference)
    }
    return correctedReference
  }

  findReferences(text) {
    const bookNames = this.allBooks().map(b => BibleBooksEnglish[b]).join('|')
    const verseRegex = new RegExp(`\\b(${bookNames})\\s+\\d+:[\\d,\\-–— ]+\\b`, 'g')
    const matches = text.matchAll(verseRegex)
    const verses = []
    for (let match of matches) {
      // Remove trailing commas and spaces
      const reference = match[0].replace(/[\s,]+$/, '')
      // Check if reference ends with a digit, otherwise ignore.
      // Couldn't get 1 Chronicles 16:9-10a to be ignored with the above verseRegex.
      if (reference.match(/\d$/)) {
        verses.push({
          reference: reference,
          start: match.index,
          end: match.index + reference.length
        })
      }
    }
    return verses
  }

  getSectionOfReference(reference) {
    const sanitizedReference = reference.trim()
    const index = this._localizedNames.findIndex(names => sanitizedReference.startsWith(names.en))
    if (index >= 0) {
      // There are 39 books in old testament. First book in new testament is at index 39.
      return index < 39 ? "OT" : "NT"
    } else {
      return null
    }
  }

  localizeReference(reference) {
    // Book Translations must be an array of objects that have
    // translations for each book in the bible, and must have
    // 1|2|3 John before John to ensure they match before it.
    const en = reference
    let es = en
    let ko = en
    let found = false
    this._localizedNames.forEach(translations => {
      const englishName = translations.en
      if (en.includes(englishName)) {
        // Colon is not a special character in regex
        // Replace globally in case there are multiple references to the same book.
        let regex = new RegExp(englishName, 'g')
        es = es.replace(regex, translations.es)
        ko = ko.replace(regex, translations.ko)
        found = true
      }
    })
    if (!found) {
      throw new Error(`Verse ${en} doesn't have valid book name`)
    }
    return { en, es, ko }
  }

  validateReference(reference) {
    if (!reference || reference.length == 0) {
      return 'is empty or null'
    }

    const bookNames = new Set(this.allBooks().map(b => BibleBooksEnglish[b]))

    const groups = reference.split(';').map(g => g.trim())
    for (let group of groups) {
      // Allow all kinds of dashes, just sanitize it out.
      const match = group.match(/(.+?)\s+(\d+):([a-z0-9,\-– ]+)/)
      if (match) {
        const [full, book] = match
        if (full.length != group.length) {
          return 'has unrecognized characters'
        }

        if (!bookNames.has(book)) {
          return `refers to an unrecognized book: ${book}`
        }
      }
    }
    return null
  }

  validateReferenceAndThrow(reference) {
    const error = this.validateReference(reference)
    if (error) {
      throw new VerseValidationError(reference, [error])
    }
  }
}

const BibleBooksData = `Genesis	1	31 25 24 26 32 22 24 22 29 32 32 20 18 24 21 16 27 33 38 18 34 24 20 67 34 35 46 22 35 43 55 32 20 31 29 43 36 30 23 23 57 38 34 34 28 34 31 22 33 26
Exodus	2	22 25 22 31 23 30 25 32 35 29 10 51 22 31 27 36 16 27 25 26 36 31 33 18 40 37 21 43 46 38 18 35 23 35 35 38 29 31 43 38
Leviticus	3	17 16 17 35 19 30 38 36 24 20 47 8 59 57 33 34 16 30 37 27 24 33 44 23 55 46 34
Numbers	4	54 34 51 49 31 27 89 26 23 36 35 16 33 45 41 50 13 32 22 29 35 41 30 25 18 65 23 31 40 16 54 42 56 29 34 13
Deuteronomy	5	46 37 29 49 33 25 26 20 29 22 32 32 18 29 23 22 20 22 21 20 23 30 25 22 19 19 26 68 29 20 30 52 29 12
Joshua	6	18 24 17 24 15 27 26 35 27 43 23 24 33 15 63 10 18 28 51 9 45 34 16 33
Judges	7	36 23 31 24 31 40 25 35 57 18 40 15 25 20 20 31 13 31 30 48 25
Ruth	8	22 23 18 22
Samuel1	9	28 36 21 22 12 21 17 22 27 27 15 25 23 52 35 23 58 30 24 42 15 23 29 22 44 25 12 25 11 31 13
Samuel2	10	27 32 39 12 25 23 29 18 13 19 27 31 39 33 37 23 29 33 43 26 22 51 39 25
Kings1	11	53 46 28 34 18 38 51 66 28 29 43 33 34 31 34 34 24 46 21 43 29 53
Kings2	12	18 25 27 44 27 33 20 29 37 36 21 21 25 29 38 20 41 37 37 21 26 20 37 20 30
Chronicles1	13	54 55 24 43 26 81 40 40 44 14 47 40 14 17 29 43 27 17 19 8 30 19 32 31 31 32 34 21 30
Chronicles2	14	17 18 17 22 14 42 22 18 31 19 23 16 22 15 19 14 19 34 11 37 20 12 21 27 28 23 9 27 36 27 21 33 25 33 27 23
Ezra	15	11 70 13 24 17 22 28 36 15 44
Nehemiah	16	11 20 32 23 19 19 73 18 38 39 36 47 31
Esther	17	22 23 15 17 14 14 10 17 32 3
Job	18	22 13 26 21 27 30 21 22 35 22 20 25 28 22 35 22 16 21 29 29 34 30 17 25 6 14 23 28 25 31 40 22 33 37 16 33 24 41 30 24 34 17
Psalms	19	6 12 8 8 12 10 17 9 20 18 7 8 6 7 5 11 15 50 14 9 13 31 6 10 22 12 14 9 11 12 24 11 22 22 28 12 40 22 13 17 13 11 5 26 17 11 9 14 20 23 19 9 6 7 23 13 11 11 17 12 8 12 11 10 13 20 7 35 36 5 24 20 28 23 10 12 20 72 13 19 16 8 18 12 13 17 7 18 52 17 16 15 5 23 11 13 12 9 9 5 8 28 22 35 45 48 43 13 31 7 10 10 9 8 18 19 2 29 176 7 8 9 4 8 5 6 5 6 8 8 3 18 3 3 21 26 9 8 24 13 10 7 12 15 21 10 20 14 9 6
Proverbs	20	33 22 35 27 23 35 27 36 18 32 31 28 25 35 33 33 28 24 29 30 31 29 35 34 28 28 27 28 27 33 31
Ecclesiastes	21	18 26 22 16 20 12 29 17 18 20 10 14
SongOfSolomon	22	17 17 11 16 16 13 13 14
Isaiah	23	31 22 26 6 30 13 25 22 21 34 16 6 22 32 9 14 14 7 25 6 17 25 18 23 12 21 13 29 24 33 9 20 24 17 10 22 38 22 8 31 29 25 28 28 25 13 15 22 26 11 23 15 12 17 13 12 21 14 21 22 11 12 19 12 25 24
Jeremiah	24	19 37 25 31 31 30 34 22 26 25 23 17 27 22 21 21 27 23 15 18 14 30 40 10 38 24 22 17 32 24 40 44 26 22 19 32 21 28 18 16 18 22 13 30 5 28 7 47 39 46 64 34
Lamentations	25	22 22 66 22 22
Ezekiel	26	28 10 27 17 17 14 27 18 11 22 25 28 23 23 8 63 24 32 14 49 32 31 49 27 17 21 36 26 21 26 18 32 33 31 15 38 28 23 29 49 26 20 27 31 25 24 23 35
Daniel	27	21 49 30 37 31 28 28 27 27 21 45 13
Hosea	28	11 23 5 19 15 11 16 14 17 15 12 14 16 9
Joel	29	20 32 21
Amos	30	15 16 15 13 27 14 17 14 15
Obadiah	31	21
Jonah	32	17 10 10 11
Micah	33	16 13 12 13 15 16 20
Nahum	34	15 13 19
Habakkuk	35	17 20 19
Zephaniah	36	18 15 20
Haggai	37	15 23
Zechariah	38	21 13 10 14 11 15 14 23 17 12 17 14 9 21
Malachi	39	14 17 18 6
Matthew	40	25 23 17 25 48 34 29 34 38 42 30 50 58 36 39 28 27 35 30 34 46 46 39 51 46 75 66 20
Mark	41	45 28 35 41 43 56 37 38 50 52 33 44 37 72 47 20
Luke	42	80 52 38 44 39 49 50 56 62 42 54 59 35 35 32 31 37 43 48 47 38 71 56 53
John	43	51 25 36 54 47 71 53 59 41 42 57 50 38 31 27 33 26 40 42 31 25
Acts	44	26 47 26 37 42 15 60 40 43 48 30 25 52 28 41 40 34 28 41 38 40 30 35 27 27 32 44 31
Romans	45	32 29 31 25 21 23 25 39 33 21 36 21 14 23 33 27
Corinthians1	46	31 16 23 21 13 20 40 13 27 33 34 31 13 40 58 24
Corinthians2	47	24 17 18 18 21 18 16 24 15 18 33 21 14
Galatians	48	24 21 29 31 26 18
Ephesians	49	23 22 21 32 33 24
Philippians	50	30 30 21 23
Colossians	51	29 23 25 18
Thessalonians1	52	10 20 13 18 28
Thessalonians2	53	12 17 18
Timothy1	54	20 15 16 16 25 21
Timothy2	55	18 26 17 22
Titus	56	16 15 15
Philemon	57	25
Hebrews	58	14 18 19 16 14 20 28 13 28 39 40 29 25
James	59	27 26 18 17 20
Peter1	60	25 25 22 19 14
Peter2	61	21 22 18
John1	62	10 29 24 21 21
John2	63	13
John3	64	14
Jude	65	25
Revelation	66	20 29 22 11 14 17 17 13 21 11 19 17 18 20 8 21 18 24 21 15 27 21`

const BibleBooksEnglish = {
  Genesis: "Genesis",
  Exodus: "Exodus",
  Leviticus: "Leviticus",
  Numbers: "Numbers",
  Deuteronomy: "Deuteronomy",
  Joshua: "Joshua",
  Judges: "Judges",
  Ruth: "Ruth",
  Samuel1: "1 Samuel",
  Samuel2: "2 Samuel",
  Kings1: "1 Kings",
  Kings2: "2 Kings",
  Chronicles1: "1 Chronicles",
  Chronicles2: "2 Chronicles",
  Ezra: "Ezra",
  Nehemiah: "Nehemiah",
  Esther: "Esther",
  Job: "Job",
  Psalms: "Psalm", // With Psalms: When referenced, it is Psalm. Full book name is actually Psalms.
  Proverbs: "Proverbs",
  Ecclesiastes: "Ecclesiastes",
  SongOfSolomon: "Song of Solomon",
  Isaiah: "Isaiah",
  Jeremiah: "Jeremiah",
  Lamentations: "Lamentations",
  Ezekiel: "Ezekiel",
  Daniel: "Daniel",
  Hosea: "Hosea",
  Joel: "Joel",
  Amos: "Amos",
  Obadiah: "Obadiah",
  Jonah: "Jonah",
  Micah: "Micah",
  Nahum: "Nahum",
  Habakkuk: "Habakkuk",
  Zephaniah: "Zephaniah",
  Haggai: "Haggai",
  Zechariah: "Zechariah",
  Malachi: "Malachi",
  Matthew: "Matthew",
  Mark: "Mark",
  Luke: "Luke",
  John: "John",
  Acts: "Acts",
  Romans: "Romans",
  Corinthians1: "1 Corinthians",
  Corinthians2: "2 Corinthians",
  Galatians: "Galatians",
  Ephesians: "Ephesians",
  Philippians: "Philippians",
  Colossians: "Colossians",
  Thessalonians1: "1 Thessalonians",
  Thessalonians2: "2 Thessalonians",
  Timothy1: "1 Timothy",
  Timothy2: "2 Timothy",
  Titus: "Titus",
  Philemon: "Philemon",
  Hebrews: "Hebrews",
  James: "James",
  Peter1: "1 Peter",
  Peter2: "2 Peter",
  John1: "1 John",
  John2: "2 John",
  John3: "3 John",
  Jude: "Jude",
  Revelation: "Revelation",
}

const BibleBookLocalizations = [
  {
    "en": "Genesis",
    "ko": "창세기",
    "es": "Génesis",
    "zhhans": "創 世 記"
  },
  {
    "en": "Exodus",
    "ko": "출애굽기",
    "es": "Éxodo",
    "zhhans": "出 埃 及 記"
  },
  {
    "en": "Leviticus",
    "ko": "레위기",
    "es": "Levítico",
    "zhhans": "利 未 記"
  },
  {
    "en": "Numbers",
    "ko": "민수기",
    "es": "Números",
    "zhhans": "民 數 記"
  },
  {
    "en": "Deuteronomy",
    "ko": "신명기",
    "es": "Deuteronomio",
    "zhhans": "申 命 記"
  },
  {
    "en": "Joshua",
    "ko": "여호수아",
    "es": "Josué",
    "zhhans": "約 書 亞 記"
  },
  {
    "en": "Judges",
    "ko": "사사기",
    "es": "Jueces",
    "zhhans": "士 師 記"
  },
  {
    "en": "Ruth",
    "ko": "룻기",
    "es": "Rut",
    "zhhans": "路 得 記"
  },
  {
    "en": "1 Samuel",
    "ko": "사무엘상",
    "es": "1 Samuel",
    "zhhans": "撒 母 耳 記 上"
  },
  {
    "en": "2 Samuel",
    "ko": "사무엘하",
    "es": "2 Samuel",
    "zhhans": "撒 母 耳 記 下"
  },
  {
    "en": "1 Kings",
    "ko": "열왕기상",
    "es": "1 Reyes",
    "zhhans": "列 王 紀 上"
  },
  {
    "en": "2 Kings",
    "ko": "열왕기하",
    "es": "2 Reyes",
    "zhhans": "列 王 紀 下"
  },
  {
    "en": "1 Chronicles",
    "ko": "역대상",
    "es": "1 Crónicas",
    "zhhans": "歷 代 志 上"
  },
  {
    "en": "2 Chronicles",
    "ko": "역대하",
    "es": "2 Crónicas",
    "zhhans": "歷 代 志 下"
  },
  {
    "en": "Ezra",
    "ko": "에스라",
    "es": "Esdras",
    "zhhans": "以 斯 拉 記"
  },
  {
    "en": "Nehemiah",
    "ko": "느헤미야",
    "es": "Nehemías",
    "zhhans": "尼 希 米 記"
  },
  {
    "en": "Esther",
    "ko": "에스더",
    "es": "Ester",
    "zhhans": "以 斯 帖 記"
  },
  {
    "en": "Job",
    "ko": "욥기",
    "es": "Job",
    "zhhans": "約 伯 記"
  },
  {
    "en": "Psalm",
    "ko": "시편",
    "es": "Salmos",
    "zhhans": "詩 篇"
  },
  {
    "en": "Proverbs",
    "ko": "잠언",
    "es": "Proverbios",
    "zhhans": "箴 言"
  },
  {
    "en": "Ecclesiastes",
    "ko": "전도서",
    "es": "Eclesiastés",
    "zhhans": "傳 道 書"
  },
  {
    "en": "Song of Solomon",
    "ko": "아가",
    "es": "Cantares",
    "zhhans": "雅 歌"
  },
  {
    "en": "Isaiah",
    "ko": "이사야",
    "es": "Isaías",
    "zhhans": "以 賽 亞 書"
  },
  {
    "en": "Jeremiah",
    "ko": "예레미야",
    "es": "Jeremías",
    "zhhans": "耶 利 米 書"
  },
  {
    "en": "Lamentations",
    "ko": "예레미야애가",
    "es": "Lamentaciones",
    "zhhans": "耶 利 米 哀 歌"
  },
  {
    "en": "Ezekiel",
    "ko": "에스겔",
    "es": "Ezequiel",
    "zhhans": "以 西 結 書"
  },
  {
    "en": "Daniel",
    "ko": "다니엘",
    "es": "Daniel",
    "zhhans": "但 以 理 書"
  },
  {
    "en": "Hosea",
    "ko": "호세아",
    "es": "Oseas",
    "zhhans": "何 西 阿 書"
  },
  {
    "en": "Joel",
    "ko": "요엘",
    "es": "Joel",
    "zhhans": "約 珥 書"
  },
  {
    "en": "Amos",
    "ko": "아모스",
    "es": "Amós",
    "zhhans": "阿 摩 司 書"
  },
  {
    "en": "Obadiah",
    "ko": "오바댜",
    "es": "Abdías",
    "zhhans": "俄 巴 底 亞 書"
  },
  {
    "en": "Jonah",
    "ko": "요나",
    "es": "Jonás",
    "zhhans": "約 拿 書"
  },
  {
    "en": "Micah",
    "ko": "미가",
    "es": "Miqueas",
    "zhhans": "彌 迦 書"
  },
  {
    "en": "Nahum",
    "ko": "나훔",
    "es": "Nahúm",
    "zhhans": "那 鴻 書"
  },
  {
    "en": "Habakkuk",
    "ko": "하박국",
    "es": "Habacuc",
    "zhhans": "哈 巴 谷 書"
  },
  {
    "en": "Zephaniah",
    "ko": "스바냐",
    "es": "Sofonías",
    "zhhans": "西 番 雅 書"
  },
  {
    "en": "Haggai",
    "ko": "학개",
    "es": "Hageo",
    "zhhans": "哈 該 書"
  },
  {
    "en": "Zechariah",
    "ko": "스가랴",
    "es": "Zacarías",
    "zhhans": "撒 迦 利 亞"
  },
  {
    "en": "Malachi",
    "ko": "말라기",
    "es": "Malaquías",
    "zhhans": "瑪 拉 基 書"
  },
  // Start new testament on index 39
  {
    "en": "Matthew",
    "ko": "마태복음",
    "es": "Mateo",
    "zhhans": "馬 太 福 音"
  },
  {
    "en": "Mark",
    "ko": "마가복음",
    "es": "Marcos",
    "zhhans": "馬 可 福 音"
  },
  {
    "en": "Luke",
    "ko": "누가복음",
    "es": "Lucas",
    "zhhans": "路 加 福 音"
  },
  {
    "en": "Acts",
    "ko": "사도행전",
    "es": "Hechos",
    "zhhans": "使 徒 行 傳"
  },
  {
    "en": "Romans",
    "ko": "로마서",
    "es": "Romanos",
    "zhhans": "羅 馬 書"
  },
  {
    "en": "1 Corinthians",
    "ko": "고린도전서",
    "es": "1 Corintios",
    "zhhans": "歌 林 多 前 書"
  },
  {
    "en": "2 Corinthians",
    "ko": "고린도후서",
    "es": "2 Corintios",
    "zhhans": "歌 林 多 後 書"
  },
  {
    "en": "Galatians",
    "ko": "갈라디아서",
    "es": "Gálatas",
    "zhhans": "加 拉 太 書"
  },
  {
    "en": "Ephesians",
    "ko": "에베소서",
    "es": "Efesios",
    "zhhans": "以 弗 所 書"
  },
  {
    "en": "Philippians",
    "ko": "빌립보서",
    "es": "Filipenses",
    "zhhans": "腓 立 比 書"
  },
  {
    "en": "Colossians",
    "ko": "골로새서",
    "es": "Colosenses",
    "zhhans": "歌 羅 西 書"
  },
  {
    "en": "1 Thessalonians",
    "ko": "데살로니가전서",
    "es": "1 Tesalonicenses",
    "zhhans": "帖 撒 羅 尼 迦 前 書"
  },
  {
    "en": "2 Thessalonians",
    "ko": "데살로니가후서",
    "es": "2 Tesalonicenses",
    "zhhans": "帖 撒 羅 尼 迦 後 書"
  },
  {
    "en": "1 Timothy",
    "ko": "디모데전서",
    "es": "1 Timoteo",
    "zhhans": "提 摩 太 前 書"
  },
  {
    "en": "2 Timothy",
    "ko": "디모데후서",
    "es": "2 Timoteo",
    "zhhans": "提 摩 太 後 書"
  },
  {
    "en": "Titus",
    "ko": "디도서",
    "es": "Tito",
    "zhhans": "提 多 書"
  },
  {
    "en": "Philemon",
    "ko": "빌레몬서",
    "es": "Filemón",
    "zhhans": "腓 利 門 書"
  },
  {
    "en": "Hebrews",
    "ko": "히브리서",
    "es": "Hebreos",
    "zhhans": "希 伯 來 書"
  },
  {
    "en": "James",
    "ko": "야고보서",
    "es": "Santiago",
    "zhhans": "雅 各 書"
  },
  {
    "en": "1 Peter",
    "ko": "베드로전서",
    "es": "1 Pedro",
    "zhhans": "彼 得 前 書"
  },
  {
    "en": "2 Peter",
    "ko": "베드로후서",
    "es": "2 Pedro",
    "zhhans": "彼 得 後 書"
  },
  {
    "en": "1 John",
    "ko": "요한일서",
    "es": "1 Juan",
    "zhhans": "約 翰 一 書"
  },
  {
    "en": "2 John",
    "ko": "요한이서",
    "es": "2 Juan",
    "zhhans": "約 翰 二 書"
  },
  {
    "en": "3 John",
    "ko": "요한삼서",
    "es": "3 Juan",
    "zhhans": "約 翰 三 書"
  },
  // Put regular John after 1/2/3 John to make sure they get translated first.
  {
    "en": "John",
    "ko": "요한복음",
    "es": "Juan",
    "zhhans": "約 翰 福 音"
  },
  {
    "en": "Jude",
    "ko": "유다서",
    "es": "Judas",
    "zhhans": "猶 大 書"
  },
  {
    "en": "Revelation",
    "ko": "요한계시록",
    "es": "Apocalipsis",
    "zhhans": "启 示 录"
  }
]

export { BibleBookLocalizations }
export default BibleMeta
