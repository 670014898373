export const INVALIDATE_PAGE = 'INVALIDATE_PAGE'
export const PRESENT_ERROR = 'PRESENT_ERROR'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'

export function invalidatePage() {
  return { type: INVALIDATE_PAGE }
}

export function presentError(error) {
  return { type: PRESENT_ERROR, error }
}

export function setPageTitle(title) {
  return { type: SET_PAGE_TITLE, title }
}
