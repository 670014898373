import DatoModel from './dato-model.js'

const fields = [
  { id: 'devotions', userEditable: true },
  {
    id: 'intro',
    constraints: { dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  { id: 'lists', userEditable: true },
  { id: 'shortId' },
  { id: 'source' },
  {
    id: 'title',
    constraints: { presence: true, length: { minimum: 3 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditable: true
  },
  { id: 'verses', userEditable: true },
  { id: 'date', userEditable: true },
  { id: 'image' },
  {
    id: 'annotations',
    json: true
  },
  { id: 'needsProofreading' },
  {
    id: 'titleEs',
    constraintsEs: { presence: true, length: { minimum: 2 }, dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  {
    id: 'introEs',
    constraintsEs: { dashes: true, ellipses: true, smartQuotes: true },
    userEditableEs: true
  },
  {
    id: 'versesEs',
    userEditableEs: true
  },
  { id: 'sourceEs' },
  { id: 'needsProofreadingEs' },
]

const links = [
  { id: 'image' },
  { id: 'imageOptions' },
  { id: 'linkedDevotions' },
  { id: 'linkedLists' },
  { id: 'linkedVerses' },
]

const TopicModel = new DatoModel('topic', fields, links)
Object.freeze(TopicModel)

export default TopicModel
