import React from 'react'

import Dispatcher from '../lib/dispatcher'
import Permissions from '../lib/permissions'

const defaultPermissions = new Permissions()

const dispatcher = new Dispatcher()

const dispatch = (payload) => {
  dispatcher.dispatch(payload)
}

const register = (func) => {
  dispatcher.register(func)
}

// Basically, a singleton.
const defaultContext = {
  dispatch,
  permissions: defaultPermissions,
  register,
  sandbox: process.env.DATO_DELIVERY_ENDPOINT.includes("/environments/")
}

const BaseContext = React.createContext(defaultContext)

export default BaseContext

export { defaultContext }
