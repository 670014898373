import invariant from 'invariant'
import _ from 'lodash'
import Language from './language.js'
import validate from './validate.js'

// https://www.datocms.com/docs/content-management-api/resources/item
export const PublishStatus = Object.freeze({
  draft: 'draft',
  updated: 'updated',
  published: 'published',
})

class DatoModel {
  // Name should match apiKey in dato.
  constructor(name, fields, links, graphQlFragment) {
    invariant(name, 'missing name')
    invariant(fields, 'missing fields')
    invariant(links, 'missing links')

    this.name = name
    this.fields = fields
    this.links = links
    this.fragment = graphQlFragment
  }

  fragmentWithName(name) {
    if (this.fragment) {
      return this.fragment.replace('__NAME__', name)
    } else {
      throw new Error('No fragment defined')
    }
  }

  getNeedsProofreadingField(language = Language.default) {
    let fieldKey = language.getLocalizedKey('needsProofreading')
    return _.find(this.fields, { id: fieldKey })
  }

  pickFieldsAndLinks(object, nullMissingFields) {
    const picked = {}
    if (!object) {
      return picked
    }
    for (let field of this.fields) {
      const value = object[field.id]
      // Use isNil so that empty strings make it through.
      if (_.isNil(value)) {
        if (nullMissingFields) {
          picked[field.id] = null
        }
        continue
      }

      // When adding values to JSON fields, it must be in a string
      if (field.json) {
        picked[field.id] = _.isString(value) ? value : JSON.stringify(value)
      } else {
        picked[field.id] = value
      }
    }
    for (let link of this.links) {
      const linkValue = object[link.id]
      if (_.isNil(linkValue)) {
        if (nullMissingFields) {
          picked[link.id] = null
        }
      } else {
        picked[link.id] = linkValue
      }
    }
    return picked
  }

  pickUserEditableFields(object, language = Language.default) {
    const fieldIds = this.userEditableFields(language).map(field => field.id)
    return _.pick(object, fieldIds)
  }

  userEditableFields(language = Language.default) {
    const fieldKey = language.getLocalizedKey('userEditable')
    return this.fields.filter(f => f[fieldKey])
  }

  // By default returns grouped format, like validate.js.
  validate(object, useFlatFormat, language = Language.default) {
    const constraints = {}
    const constraintsKey = language.getLocalizedKey('constraints')

    for (let field of this.fields) {
      if (field[constraintsKey]) {
        constraints[field.id] = field[constraintsKey]
      }
    }
    for (let link of this.links) {
      if (link[constraintsKey]) {
        constraints[link.id] = link[constraintsKey]
      }
    }

    if (useFlatFormat) {
      return validate(object, constraints, { format: 'flat' })
    } else {
      return validate(object, constraints)
    }
  }
}

export default DatoModel
