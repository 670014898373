import DatoModel from './dato-model.js'

// format: /^[^<>]+$/g keeps failing now, so take them out
// Removed length constraints since verse can be in editing state with
// some fields blank
const fields = [
  {
    id: 'contentKjv',
    constraints: { presence: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'contentKor',
    constraints: { presence: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'contentRva',
    constraints: { presence: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'contentWeb',
    constraints: { presence: true, smartQuotes: true },
    userEditable: true
  },
  {
    id: 'reference',
    constraints: { presence: true, verse: true },
  },
  {
    id: 'shortId',
    constraints: { presence: true },
  },
  { id: 'needsProofreading' },
]

const links = [
]

// So it can be embedded in other fragments
const verseFragmentBody = `
  contentKjv 
  contentKor 
  contentRva 
  contentWeb 
  id
  needsProofreading
  reference 
  shortId`

const fragment = `fragment __NAME__ on VerseRecord {
  ${verseFragmentBody}
}`

const Verse = new DatoModel('verse', fields, links, fragment)
// A graphQL fragment that has just enough info to have its status checked, and identified in UI.
Verse.statusFragmentWithName = (name) => {
  return `fragment ${name} on VerseRecord {
    contentKor
    needsProofreading
    reference
  }`
}
Object.freeze(Verse)

const verseTranslations = Object.freeze({
  kjv: ['contentKjv', 'reference', 'en', 'en_US'],
  kor: ['contentKor', 'reference_ko', 'ko', 'ko_KR'],
  rva: ['contentRva', 'reference_es', 'es', 'es_LA'],
  web: ['contentWeb', 'reference', 'en', 'en_US'],
})

export { verseFragmentBody, verseTranslations }

export default Verse
