const defaults = new Map()

/// JS version of UserDefaults from iOS that uses local storage
class UserDefaults {
  static standard() {
     return standard
  }

  getObject(key) {
    return window.localStorage.getItem(key) || defaults.get(key)
  }

  // Set default values for keys, overrides previously set values if duplicate.
  registerDefaults(defaultsObject) {
    Object.keys(defaultsObject).forEach(key => {
      if (defaultsObject[key] !== undefined) {
        defaults.set(key, defaultsObject[key])
      }
    })
  }

  removeObject(key) {
    window.localStorage.removeItem(key)
  }

  // Using JS convention of passing key first.
  setObject(key, object) {
    window.localStorage.setItem(key, object)
  }
}

const standard = new UserDefaults()

export default UserDefaults
