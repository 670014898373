import React from 'react'
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap'

import { auth } from '../services/firebase-services'
import useFirebaseAuth from '../hooks/use-firebase-auth'
import ActivityButton from './activity-button'
import UserDefaults from '../lib/user-defaults'

const cardClassName = "p-5 my-5"
const signInEmailKey = "emailForSignIn"

const defaults = new UserDefaults()

function CompleteSignInView(props) {
  const appName = props.appName
  const [email, setEmail] = React.useState(defaults.getObject(signInEmailKey))
  const [formEmail, setFormEmail] = React.useState(null)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    const signIn = async () => {
      try {
        await auth.signInWithEmailLink(email, window.location.href)
        defaults.removeObject(signInEmailKey)
        // Replace url without querystring params.
        // HACK: nuking all querystrings
        // https://stackoverflow.com/a/41061471/31605
        window.history.replaceState(null, null, window.location.pathname)
      } catch (error) {
        setError(error)
      }
    }
    if (email) {
      signIn()
    }
  }, [email])

  if (email) {
    return (<Card className={cardClassName}>
      <Card.Title>Siging you in to {appName}</Card.Title>
      <Card.Text>We're processing the magic sign in link from your email.</Card.Text>
      {error && <Alert variant="danger">{error.message}</Alert>}
      <Spinner size="lg" />
    </Card>)
  } else {
    const onContinueClicked = () => {
      setEmail(formEmail)
    }
    return (<Card className={cardClassName}>
      <Card.Title>Signing in to {appName}</Card.Title>
      <Card.Text>What was the <strong>email address</strong> you entered to get the magic link?</Card.Text>
      {error && <Alert variant="danger">{error.message}</Alert>}
      <Form>
        <Form.Group>
          <Form.Control type="text" placeholder="email address" defaultValue={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
        </Form.Group>
        <Button variant="primary" className="mt-3" onClick={onContinueClicked}>Continue →</Button>
      </Form>
    </Card>)
  }
}

function SendEmailLinkForm(props) {
  const appName = props.appName
  const redirectUrl = window.location.href
  const [email, setEmail] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isSending, setIsSending] = React.useState(false)
  const [sent, setSent] = React.useState(false)
  const onSendClicked = async (e) => {
    e.preventDefault()
    setIsSending(true)
    try {
      await auth.sendSignInLinkToEmail(email, {
        handleCodeInApp: true,
        url: redirectUrl,
      })
      defaults.setObject(signInEmailKey, email)
      setSent(true)
    } catch (error) {
      setError(error)
    }
    setIsSending(false)
  }

  if (sent) {
    return (<Card className={cardClassName}>
      <Card.Title>We sent an email to <strong>{email}</strong>.<br />Click the link in that email to sign into {appName}.</Card.Title>
      <Card.Text className="text-muted">The link expires in 24 hours, so be sure to use it soon.</Card.Text>
    </Card>)
  } else {
    return (<Card className={cardClassName}>
      <Card.Title>Sign in to {appName}</Card.Title>
      <Card.Text>What is your sign-in <strong>email address</strong>?</Card.Text>
      {error && <Alert variant="danger">{error.message}</Alert>}
      <Form onSubmit={onSendClicked}>
        <Form.Group>
          <Form.Control type="text" placeholder="email address" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <ActivityButton variant="primary" className="mt-3" onClick={onSendClicked} isActive={isSending}>Continue →</ActivityButton>
      </Form>
    </Card>)
  }
}

function SignInView() {
  const [user, authLoading, authError] = useFirebaseAuth()
  if (user || authLoading) {
    return null
  }

  const appName = "Joyverse CMS"

  if (auth.isSignInWithEmailLink(window.location.href)) {
    return <CompleteSignInView appName={appName} error={authError} user={user} />
  } else {
    return <SendEmailLinkForm appName={appName} />
  }
}

export default SignInView
