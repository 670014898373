import { initializeApp } from 'firebase/app'
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink, signOut } from 'firebase/auth'

const config = JSON.parse(process.env.REACT_FIREBASE_CONFIG)
initializeApp(config)

const firebaseAuth = getAuth()

// Collect needed methods on this object, as backwards compatibility
const auth = {}
auth.initialized = false
auth.isSignInWithEmailLink = (emailLink) => {
  return isSignInWithEmailLink(firebaseAuth, emailLink)
}
auth.onAuthStateChanged = (nextOrObserver, error, completed) => {
  return onAuthStateChanged(firebaseAuth, nextOrObserver, error, completed)
}
auth.sendSignInLinkToEmail = (email, actionCodeSettings) => {
  return sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings)
}
auth.signInWithEmailLink = (email, emailLink) => {
  return signInWithEmailLink(firebaseAuth, email, emailLink)
}
auth.signOut = () => {
  return signOut(firebaseAuth)
}

onAuthStateChanged(firebaseAuth, () => auth.initialized = true, () => auth.initialized = true)

export { auth }
