import DatoModel from './dato-model.js'

const fields = [
  { id: 'filename' },
  { id: 'muxId' },
  { 
    id: 'muxPlaybackId',
    constraints: { presence: true, length: { minimum: 5 } },
  },
  { id: 'storageId' },
  { id: 'needsProofreading' }
]

const links = []

const fragment = `fragment __NAME__ on AudioRecord {
  id
  filename
  muxId
  muxPlaybackId
  needsProofreading
}`

const AudioModel = new DatoModel('audio', fields, links, fragment)
// A graphQL fragment that has just enough info to have its status checked, and identified in UI.
AudioModel.statusFragmentWithName = (name) => {
  return `fragment ${name} on AudioRecord {
    muxId
    muxPlaybackId
    needsProofreading
  }`
}
Object.freeze(AudioModel)

export default AudioModel
