import axios from 'axios'

class ApiClient {
  constructor(user, claims) {
    // Use is from firebase auth
    this.datoToken = claims ? claims.dato : null
    this.user = user
  }

  async addOrChangeDevotionInDigest(date, devotionId) {
    return this._post('/api/addOrChangeDevotionInDigest', { date, devotionId })
  }

  async addImageOptionToDigest(digestId, unsplashUrl) {
    return this._post('/api/addImageOptionToDigest', { digestId: digestId, sourceUrl: unsplashUrl })
  }

  async addImageOptionToTopic(topicId, sourceUrl) {
    return this._post('/api/addImageOptionToTopic', { topicId, sourceUrl })
  }

  async commitDevotionUserEdits(id, fields, languageId) {
    return this._post('/api/commitDevotionUserEdits', { id, fields, languageId })
  }

  async commitListUserEdits(id, fields) {
    return this._post('/api/commitListUserEdits', { id, fields })
  }

  async commitTopicUserEdits(id, fields, languageId) {
    return this._post('/api/commitTopicUserEdits', { id, fields, languageId })
  }

  async commitVerseUserEdits(id, fields) {
    return this._post('/api/commitVerseUserEdits', { id, fields })
  }

  async getDocumentPreviewForFile(file) {
    let data = new FormData()
    data.append('document', file)

    const document = await this._post('/api/previewSourceDocument', data)
    return document
  }

  async getRandomUsableImage() {
    return await this._post('/api/getRandomUsableImage')
  }

  async importSourceDocumentPreview(document) {
    return this._post('/api/importSourceDocumentPreview', { document: document })
  }

  async markItemProofread(itemId, languageId) {
    return this._post('/api/markItemProofread', { itemId, languageId })
  }

  async queryDato(data) {
    return axios({
      data,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.datoToken}`,
        'Content-Type': 'application/json',
      },
      method: 'post',
      url: process.env.DATO_DELIVERY_ENDPOINT,
    })
  }

  async removeImageOptionFromDigest(digestId, imageId) {
    return this._post('/api/removeImageOptionFromDigest', { digestId, imageId })
  }

  async removeImageOptionFromTopic(topicId, imageId) {
    return this._post('/api/removeImageOptionFromTopic', { topicId, imageId })
  }

  async resolveDevotionReferences(devotionId) {
    return this._post('/api/resolveDevotionReferences', { devotionId: devotionId })
  }

  async resolveListReferences(listId) {
    return this._post('/api/resolveListReferences', { listId: listId })
  }

  async resolveTopicReferences(topicId) {
    return this._post('/api/resolveTopicReferences', { topicId: topicId })
  }

  async selectDevotionForDigest(digestId, devotionReference) {
    return this._post('/api/selectDevotionForDigest', { digestId: digestId, devotionReference: devotionReference })
  }

  async selectImageOptionForDigest(digestId, imageId) {
    return this._post('/api/selectImageOptionForDigest', { digestId, imageId })
  }

  async selectImageOptionForTopic(topicId, imageId) {
    return this._post('/api/selectImageOptionForTopic', { topicId, imageId })
  }

  async selectVerseForDevotion(devotionId, verseReference) {
    return this._post('/api/selectVerseForDevotion', { devotionId: devotionId, verseReference: verseReference })
  }

  async updateImage(imageId, data) {
    return this._post(`/api/updateImage/${imageId}`, data)
  }

  async getImagesForImport(unsplashUrl, page = 1, perPage) {
    return this._post('/api/getImagesForImport', { unsplashUrl, page, perPage })
  }

  async addImageFromUnsplash(unsplashId, status) {
    return this._post('/api/addImageFromUnsplash', { unsplashId, status })
  }

  // Post to url with user token, and parse json response.
  async _post(url, data) {
    const token = await this.user.getIdToken()
    try {
      const contentType = (data instanceof FormData) ? 'multipart/form-data' : 'application/json'
      const result = await axios({
        data: data,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': contentType,
        },
        method: 'post',
        url: url,
      })
      return result ? result.data : {}
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        const error = new Error(`${e.response.status}: ${e.response.data.message}`)
        throw error
      } else {
        throw e
      }
    }
  }
}

export default ApiClient
