import _ from 'lodash'
import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function ActivityButton(props) {
  const { children, isActive, variant, ...rest } = props
  if (isActive) {
    // In Bootstrap 4, if button variant is outline, the spinner "disappears" because it's white.
    // So we detect outlines and set the appropriate spinner variant to match button outline color.
    let spinner = null
    const match = _.isString(variant) ? variant.match(/^outline-(\w+)/) : null
    if (match) {
      spinner = <Spinner as="span" size="sm" animation="border" variant={match[1]} />
    } else {
      spinner = <Spinner as="span" size="sm" animation="border" />
    }
    return <Button variant={variant} {...rest} disabled={true}>{spinner} {children}</Button>  
  }
  return <Button variant={variant} {...rest}>{children}</Button>
}

export default ActivityButton
