import DatoModel from './dato-model.js'

export const cropRatios = Object.freeze({
  portraitCrop: '9:16',
  tallCrop: '1:2',
  wideCrop: '1.91:1',
  squareCrop: '1:1',
})

// TODO: deprecate
export const cropProps = Object.freeze({
  '9:16': 'portraitCrop',
  '1:2': 'tallCrop',
  '1.91:1': 'wideCrop',
  '1:1': 'squareCrop',
})

const fields = [
  { id: 'color' },
  { id: 'height' },
  { id: 'portraitCrop' },
  { id: 'shortId' },
  { id: 'source' },
  { id: 'sourceData', json: true },
  { id: 'sourceDynamicImage' },
  { id: 'squareCrop' },
  { id: 'tallCrop' },
  { id: 'wideCrop' },
  { id: 'width' },
  { id: 'approvalStatus' },
]

const fragment = `fragment __NAME__ on ImageRecord {
  id 
  portraitCrop 
  shortId 
  source 
  approvalStatus
}`

const links = []

const ImageModel = new DatoModel('image', fields, links, fragment)
Object.freeze(ImageModel)

export default ImageModel
